@import '../../styles/lib/sign';

html {
    body {
        .box {
            @include sign-button-area();
            .button {
                @include sign-button();
                &.sub {
                    background-color: #777;
                }
            }
        }
    }
}

.button {
    & + & {
        margin-top: 20px;
        @include mobile {
            margin-top: 12px;
        }
    }
}
