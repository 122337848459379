@import '../../../styles/lib/mixin';

html {
    body {
        .post-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $main-color;
            box-shadow: $base-box-shadow;
            width: 140px;
            height: 48px;
            border-radius: $button-border-radius;

            position: absolute;
            top: -63px;
            right: 24px;

            transition: all 0.15s;

            &:hover {
                background-color: $main-tonedown;
                box-shadow: $base-box-shadow;
            }
            .text {
                color: #fff;
                font-weight: bold;
                font-size: $desktop16px;
                @include mobile {
                    display: none;
                }
            }
            .image {
                margin-left: 12px;
                width: 16px;
                @include mobile {
                    width: 25px;
                    margin-left: 0;
                }
            }
            @include mobile {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: fixed;
                top: auto;
                right: 24px;
                bottom: $mobile-bottom + 24px;
                z-index: 500;
            }
        }
    }
}
