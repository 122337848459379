@import '../../styles/lib/mixin';

@mixin title {
    font-size: $desktop25px;
    border-bottom: solid 2px $main-color;
    letter-spacing: -2px;
}

@mixin more {
    font-size: $desktop15px;
    font-weight: bold;
    color: $main-color;
    @include mobile {
        font-size: $desktop10px;
    }
}

.title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    @include mobile {
        margin-bottom: 20px;
    }
    .title {
        @include title;
        margin: 0;
    }
    .more {
        @include more;
    }
}
