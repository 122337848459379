@import '../../styles/lib/mixin';

.list {
    list-style: none;
    font-size: $desktop14px;

    .item {
        margin-bottom: 12px;
    }
}

.label {
    display: flex;
    align-items: center;
    font-size: $desktop14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #666;
    .checkbox[type='checkbox'] {
        position: relative;
        appearance: auto;
    }
    .description {
        margin-left: 8px;
    }
}
