.success {
  :global(.MuiPaper-root) {
    background-color: #4caf50;
  }
}

.error {
  :global(.MuiPaper-root) {
    background-color: #f44336;
  }
}

.warning {
  :global(.MuiPaper-root) {
    background-color: #ff9800;
  }
}

.info {
  :global(.MuiPaper-root) {
    background-color: #2196f3;
  }
}
.up {
  margin-bottom: 60px;
  @media (max-width: 600px) {
    margin-bottom: 74px;
  }
}

.snackbar {
  :global(.MuiPaper-root) {
    // transition: all .225s ease-in-out !important;
    font-size: 1.333rem;
  }
}
