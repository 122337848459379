@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.profile {
  width: 100%;
  display: flex;
  @include mobile {
    display: block;
  }
  .image-view {
    display: flex;
    justify-content: center;
    .profile-image {
      position: relative;
      width: 150px;
      height: 150px;
    }
  }
  .info {
    position: relative;
    width: 100%;
    margin-left: 40px;
    @include mobile {
      margin-left: 0;
      margin-top: 24px;
      text-align: center;
    }
    .nickname {
      font-size: $desktop25px;
      @include mobile {
        @include text-overflow(1);
      }
    }
    .name {
      font-size: $desktop13px;
      color: #555;
    }
    .email {
      font-size: $desktop15px;
      font-weight: 500;
      color: #333;
      margin-bottom: 16px;
    }
    .introduce {
      font-size: $desktop20px;
      font-weight: 500;
      line-height: 1.5;
      color: #333333;
    }
    .setting {
      position: absolute;
      top: -12px;
      right: -12px;
      width: 50px;
      height: 50px;
      .image {
        width: 100%;
        height: 100%;
      }
      @include mobile {
        top: -186px;
      }
    }
  }
}
