@import '../../styles/lib/mixin';

.box {
    .label {
        font-size: $desktop15px;
        margin-bottom: 5px;
    }
    .input-box {
        display: flex;
        height: 48px;
        @include mobile {
            height: 40px;
        }
        .input {
            transition: all 0.15s;
            width: 100%;
            height: 100%;
            padding: 0 10px;
            border: 1px solid #e5e5e5;
            &:focus {
                border: 1px solid #0066ff;
            }
        }
        .auth {
            position: relative;
            width: 100%;
            height: 100%;
            .time {
                color: #999;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                right: 10px;
            }
        }
        .send-button {
            font-size: $desktop12px;
            margin-left: 16px;
            max-width: 120px;
            min-width: 120px;
            height: 100%;
            background-color: #565656;
            color: #fff;
            transition: all 0.15s;
            @include mobile {
                max-width: 90px;
                min-width: 90px;
                margin-left: 8px;
            }
            &:hover {
                background-color: #656565;
            }
            &.check {
                background-color: $main-color;
            }
            &.disable {
                background-color: $disabled-button;
            }
        }
    }
}

.input-box {
    & + & {
        margin-top: 12px;
    }
}
