@import '../../styles/lib/mixin';

.modal {
    max-width: 380px;
    background-color: #fff;
    padding: 30px 40px;
    @include mobile {
        padding: 30px 20px;
    }
    .table {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
    .close {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
    }
}