@import '../../styles/lib/mixin';

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 360px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;

  z-index: -1;
  visibility: hidden;

  @include mobile {
    width: 300px;
  }
  &.open {
    z-index: 3000;
    visibility: visible;
  }
  .area {
    width: 100%;
    height: 100%;
    border: solid 1px #dbdbdb;
    .content {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 80px;
      @include mobile {
        margin-top: 35px;
        margin-bottom: 60px;
      }
      padding: 0 20px;
      word-break: keep-all;
      .title {
        font-size: 1.833rem;
        color: #222;
      }
      .text {
        margin-top: 30px;
        font-size: 1.333rem;
        color: #555;
        @include mobile {
          margin-top: 20px;
        }
      }
    }
  }

  .bottom {
    position: absolute;
    display: table;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;

    @include mobile {
      height: 40px;
    }
    .button {
      width: 100%;
      height: 100%;
      font-size: 1.5rem;
      border: solid 1px #dbdbdb;
      &.active {
        border: none;
        font-weight: bold;
        background-color: $main-color;
        color: #fff;
      }
    }
  }
  &.confirm {
    .button {
      width: 50%;
      &:first-child {
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }
}

:global(.MuiCircularProgress-svg) {
  color: $main-color;
}
