@import '../../styles/lib/sign';

.container {
    @include container();
    .wrapper {
        @include sign-wrapper();
        padding-bottom: 40px;
        @include mobile {
            padding-bottom: 80px;
        }
        .note {
            @include sign-note();
        }
        .form-box {
            margin-top: 30px;
            font-size: $desktop15px;
            .auth {
                margin: 20px 0;
                @include mobile {
                    margin: 16px 0;
                }
            }
        }

        .signup-alert {
            font-size: $desktop12px;
            margin-bottom: 32px;
            word-break: keep-all;
            .link {
                color: $main-color;
            }
        }
    }
}