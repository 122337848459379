@import '../../styles/lib/mixin';

.container {
    width: 100%;
    padding: 60px 0;
    @include container();
    .content {
        @include wrapper(20px);
        @include word-content();
    }
}
