@import '../../styles/lib/mixin';

.tabs {
    display: flex;
    width: 100%;
    .tab {
        width: 100%;
        font-size: $desktop17px;
        font-weight: bold;
        color: #666;
        letter-spacing: -1px;
        transition: all 0.3s ease-in-out;
        word-break: keep-all;
        &.selected {
            color: $main-color;
        }
        @include mobile {
            font-size: $desktop15px;
        }
    }
}
