@import '../../styles/lib/mixin';

html {
    body {
        .faq {
            overflow: hidden;
            background-color: #fff;
            .title {
                font-size: $desktop20px;
                letter-spacing: -1px;
                color: #000;
                display: flex;
            }
            .detail {
                font-size: $desktop17px;
                display: flex;
            }
            .left {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                font-size: $desktop25px;
                font-weight: bold;
            }
            .right {
                display: flex;
                align-items: center;
                padding-left: 20px;
                letter-spacing: -0.5px;
            }
        }
    }
}

.faq {
    & + & {
        margin-top: 16px;
        @include mobile {
            margin-top: 12px;
        }
    }
}