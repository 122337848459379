@import '../../styles/lib/mixin';

.keywords {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    padding-bottom: 10px;
    z-index: 200;
    box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.08);
    border: 1px solid #707070;
    border: {
        top: none;
        bottom: {
            left-radius: 10px;
            right-radius: 10px;
        }
    }
    @include mobile {
        top: 40px;
    }
    .item {
        padding: 8px 24px;
        font-size: $desktop14px;
        transition: background 0.15s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: #efefef;
        }
        .keyword {
            @include text-overflow(1);
        }
    }
}
