@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

$feed-line-color: #3b3b3b;

.info-item {
  width: 25%;
  padding-right: 10px;
  color: $feed-line-color;
  font-size: $desktop12px;
  .info-name {
    font-weight: lighter;
  }
  .info-value {
    @include mobile {
      @include text-overflow(1);
      width: 100%;
    }
    font-weight: bold;
  }
}

.skeleton-top-item {
  @include skeleton-wrapper(20px, 3px);
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    @include skeleton-box(100%, 100%);
  }
}
