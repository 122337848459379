@import '../../styles/lib/mixin';

.box {
    width: 100%;
    .input {
        width: 100%;
        height: 36px;
        padding: 0 5px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid;
        @include border-input();

        &.error {
            border-bottom-color: #ff0000;
        }
    }
    select {
        background-position: calc(100% - 5px) 50%;
        background-repeat: no-repeat;
        background-image: url('../../images/svg/selectbox/arrow.svg');
    }
    & + & {
        margin-top: 20px;
        @include mobile {
            margin-top: 16px;
        }
    }
}
