@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

.container {
    @include container();
    @include mobile {
        padding-top: 0px;
    }

    .wrapper {
        @include wrapper(0px);
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
