@import '../../../styles/lib/sign';

.container {
  @include container();
  .tabs {
    @include wrapper(40px);
    display: flex;
    @include mobile {
      padding: 0;
      padding-top: 20px;
    }
  }
  .wrapper {
    @include sign-wrapper();
    @include mobile {
      padding-bottom: 80px;
    }
    .note {
      @include sign-note();
    }
  }
}
