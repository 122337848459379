@import '../../../styles/lib/mixin';

html {
    body {
        .post-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $main-color;
            font-size: $desktop16px;
            color: #fff;
            border-radius: $button-border-radius;
            font-weight: bold;
            width: 160px;
            height: 48px;

            transition: all .15s;
            &:hover {
                background-color: $main-tonedown;
                box-shadow: $base-box-shadow;
            }

            @include mobile {
                width: 120px;
                height: 40px;
            }
            .pencil {
                margin-left: 12px;
                width: 16px;
            }
        }
    }
}
