@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

.description {
    font-size: $desktop15px;
    &.center {
        text-align: center;
    }
    .jump {
        @include jump-animation(10s);
        .question {
            margin-right: 8px;
        }    
        .link {
            color: $main-color;
            &.linkbold {
                font-weight: bold;
            }
        }

    }
}
