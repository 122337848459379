@import '../../styles/lib/mixin';

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg {
        width: 100px;
        height: 100px;
    }
    .text {
        margin-top: 10px;
        font-size: $desktop24px;
        color: #8b8b8b;
    }
}
