@import '../../../styles/lib/mixin';

.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  border: solid #9e9e9e;
  overflow: hidden;
  &.on {
    border-color: $main-color;
  }
  .image,
  svg {
    width: 100%;
    height: 100%;
  }
}
