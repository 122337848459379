@import '../../styles/lib/mixin';

html {
  body {
    .sns-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      border-radius: 1px;
      background-color: #fff;
      font-size: $desktop14px;
      font-weight: 500;
      letter-spacing: -0.7px;
      color: #fff;

      &.google {
        background-color: #4285f4;
      }
      &.facebook {
        background-color: #4267b2;
      }
      &.naver {
        background-color: #03c75a;
      }
      &.kakao {
        color: #191600;
        background-color: #fee500;
      }

      .sns-name {
        display: inline-block;
        text-align: left;
        white-space: nowrap;
        &::before {
          position: absolute;
          content: '';
          left: 1px;
          top: 50%;
          transform: translateY(-50%);
          width: 38px;
          height: 38px;
          border-radius: 1px;
          background-size: 18px 18px;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff;
        }
        &.google::before {
          background-image: url('../../images/svg/social/google_login.svg');
        }
        &.facebook::before {
          background-image: url('../../images/svg/social/facebook_login.svg');
        }
        &.kakao::before {
          background-image: url('../../images/svg/social/kakao_login.svg');
        }
        &.naver::before {
          background-image: url('../../images/svg/social/naver_login.svg');
        }
      }
    }
  }
}
.sns-button {
  & + & {
    margin-top: 12px;
  }
}
