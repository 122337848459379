@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.skeleton-faq {
    @include skeleton-wrapper(55px, 10px);

    & + & {
        margin-top: 16px;
        @include mobile {
            margin-top: 12px;
        }
    }
    .skeleton-title {
        @include skeleton-box(60%, 100%);
    }
}
