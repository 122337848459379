@import '../../styles/lib/mixin';

.header {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;

    @include mobile {
        top: auto;
        bottom: 0;
        border-bottom: none;
        border-top: 1px solid #ebebeb;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
        background-color: #fcfcfc;
    }
    .wrapper {
        @include wrapper(0px);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @include mobile {
            width: 100%;
        }
        .logo-area {
            @include mobile {
                display: none;
            }
            .logo {
                display: flex;
            }
        }
        .nav {
            height: 100%;
            @include mobile {
                width: 100%;
            }
            .nav-list {
                width: 280px;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                @include mobile {
                    width: 100%;
                    // justify-content: space-around;
                }
            }

            .list-element {
                display: inline-block;
                @include mobile {
                    margin: 0;
                }
                &.home {
                    display: none;
                    @include mobile {
                        display: inline-block;
                    }
                }
                .link {
                    display: flex;
                    height: 100%;
                    &:hover + .dropdown {
                        padding-top: 10px;
                        opacity: 1;
                        height: auto;
                    }
                    .link-btn {
                        width: 40px;
                        height: 40px;
                        padding: 0;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

.dropdown {
    z-index: 100;
    transition: opacity 0.3s;
    position: absolute;
    top: 48px;
    right: 0;
    opacity: 0;
    height: 0;
    @include mobile {
        display: none;
    }
    &:hover {
        padding-top: 10px;
        opacity: 1;
        height: auto;
    }
    &.unopenable {
        padding-top: 0px !important;
        opacity: 0 !important;
        height: 0 !important;
    }
    .dropdown-list {
        display: flex;
        flex-direction: column;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.16);
        width: 145px;
        height: 100%;
        overflow-y: hidden;
        border-radius: 5px;
        background-color: #fff;

        .dropdown-element {
            font-size: $desktop18px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            padding: 5px;
            transition: all 0.15s;
            letter-spacing: -1.5px;
            &:hover {
                background-color: #dfdfdf;
            }
            .navigator {
                display: block;
                width: 100%;
                height: 100%;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
