@import '../../styles/lib/mixin';

$min-width: 611px;

$bar-color: #000;
$color: #666;
$dark-color: #fff;

.viewer {
  position: relative;

  .more {
    position: absolute;
    bottom: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;
    border-top: solid 2px #222;
    background: linear-gradient(to top, #fffe, #fffe, #fffd, #fffa);

    &:hover {
      background: linear-gradient(
        to top,
        #fffe,
        #fffe,
        #fffd,
        #efefefaa
      );
      cursor: pointer;
    }
    @include mobile {
      height: 48px;
    }
    &.open {
      position: relative;
      margin-top: 20px;
    }
    .more-text {
      color: #777;
      font-size: $desktop18px;
    }
  }
}

.group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  & + & {
    margin-top: 80px;
    @include mobile {
      margin-top: 40px;
    }
  }
  .group-title {
    border-bottom: 1px solid $bar-color;
    font-size: $desktop20px;
    height: 32px;
    .group-number {
      margin-right: 50px;
    }
  }
  .grid-10 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    @include mobile {
      transform: scale(0.6);
    }
    margin-top: 25px;
    min-width: 600px;
    height: 247px;
    border: 1px solid $color;
    border-radius: 58px;
    background-color: #fff;
    line-height: 1.2;
    &.dark {
      border: solid$dark-color;
    }

    .encoder-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-height: 116px;
      height: 100%;
      padding: 0 26px 0 26px;
      margin-top: 21px;
      @include mobile {
        transform: scale(1);
      }

      .out-line {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20.9%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid $color;

        &.dark {
          border: solid$dark-color;
        }
        .in-line {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 85%;
          height: 85%;
          border-radius: 50%;
          border: 1px solid $color;
          &.dark {
            border: solid$dark-color;
          }

          word-break: keep-all;

          .shortcut-name {
            text-align: center;
            font-size: $desktop18px;

            &.dark {
              color: $dark-color;
            }
          }
        }
      }
    }

    .button-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 89.39%;
      max-height: 76px;
      min-height: 42px;
      height: 100%;
      border-radius: 17px;
      border: 1px solid $color;
      margin: 13px auto 0 auto;
      padding: 0 15px 0 15px;

      &.dark {
        border: solid $dark-color;
      }

      .shortcut-button,
      .group-button {
        border: 1px solid $color;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18.5%;
        height: 64.47%;
        border-radius: 8px;

        &.dark {
          border: solid$dark-color;
        }

        .button-cursor {
          position: absolute;
          bottom: 0px;
          right: 9px;
        }

        .shortcut-name {
          font-size: $desktop15px;
        }
      }
      .item-name {
        font-size: 15px;
        text-align: center;
        word-break: keep-all;

        &.dark {
          color: $dark-color;
        }
      }

      .shortcutButton {
        border: 2px solid $color;
      }

      .groupButton {
        border: 2px solid $color;
      }
    }
  }

  .groupComment {
    margin-top: 20px;
    font-size: $desktop15px;
  }
}
