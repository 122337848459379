$skeleton-background: #f5f6f8;

@mixin skeleton-box($width, $height) {
    border: solid 1px #dfdfdf;
    width: $width;
    height: $height;
}

@mixin skeleton-content {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    left: -100%;
    top: 0;
    background: linear-gradient(
        to right,
        $skeleton-background,
        #aaa,
        #999,
        #aaa,
        $skeleton-background
    );
    animation: skeleton 2s;
    animation-iteration-count: infinite;
    opacity: 0.3;
}

@mixin skeleton-wrapper($height, $padding) {
    position: relative;
    width: 100%;
    height: $height;
    background-color: $skeleton-background;
    padding: $padding;
    border-radius: $padding;
    overflow: hidden;
    &::before {
        @include skeleton-content();
    }
}

@mixin skeleton-animation($blur) {
    @each $prefix in -moz-, -o-, -webkit-, -ms- {
        #{$prefix}filter: blur($blur);
    }
}

@keyframes skeleton {
    from {
        left: -100%;
    }
    to {
        left: 100%;
    }
}
