@import '../../styles/lib/mixin';

$switch-background-color: #ebebeb;

.box {
  display: flex;
  align-items: center;
  margin-left: 12px;
  .label {
    white-space: nowrap;
    margin-right: 8px;
    font-size: $desktop12px;
    color: #999;
  }
  .input {
    display: none;
  }
  .toggle {
    width: 48px;
    height: 24px;
    .wrapper {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-color: $switch-background-color;
      transition: background-color 0.3s ease-in-out;
      &.checked {
        background-color: $main-color;
        .switch {
          .button {
            transform: translateX(100%);
            border-color: $main-color;
          }
        }
      }
      .switch {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .button {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          border-radius: 50%;
          border: solid 1px $switch-background-color;
          transition: all 0.3s ease-in-out;
          &:hover::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150%;
            height: 150%;
            border-radius: 50%;
            background-color: #0002;
          }
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
    }
  }
}
