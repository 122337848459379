@import '../../styles/lib/mixin';

.container {
    @include mobile {
        display: none;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wrapper {
        @include wrapper(40px);
        padding-bottom: 15px;
        .title {
            @include title();
        }
    }
}
