@import '../../styles/lib/mixin';

.box {
  width: 100%;
  .input {
    width: 100%;
    height: 48px;
    padding: 0 10px;

    border: solid 1px;
    @include border-input();

    @include mobile {
      height: 40px;
    }
    background-color: #fff;
  }
  select {
    background-color: #fff;
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
    background-image: url('../../images/svg/selectbox/arrow.svg');
  }
  & + & {
    margin-top: 20px;
    @include mobile {
      margin-top: 16px;
    }
  }
}
