@import '../../styles/lib/mixin';

.search {
    width: 100%;
    height: 50px;
    position: relative;
    @include mobile {
        height: 40px;
    }
    .wrapper {
        border: 1px solid #707070;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        padding: 5px;
        background-color: #fff;
        &.view {
            border: {
                bottom: {
                    color: #ebebeb;
                    left-radius: 0;
                    right-radius: 0;
                }
            }
        }
        .search-input {
            width: 100%;
            height: 100%;
            font-size: $desktop17px;
            padding-left: 40px;
            border: none;
            outline: none;
        }
        .button {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            @include mobile {
                width: 30px;
                height: 30px;
            }
            &.search {
                left: 5px;
            }
            &.clear {
                right: 5px;
            }
            &.loading {
                right: 5px;
                width: 30px;
                height: 30px;
            }
            .image {
                width: 20px;
                height: 20px;
            }
        }
    }
}
