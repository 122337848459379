@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.profile {
    @include skeleton-wrapper(auto, 5px);
    display: flex;
    @include mobile {
        display: block;
    }
    .image-view {
        display: flex;
        justify-content: center;
        .profile-image {
            width: 150px;
            height: 150px;
        }
    }
    .info {
        width: 100%;
        margin-left: 40px;
        @include mobile {
            margin-left: 0;
            margin-top: 24px;
            text-align: center;
        }
        .nickname {
            @include skeleton-box(60%, 3em);
            @include mobile {
                margin: 0 auto;
            }
        }
        .name {
            @include skeleton-box(100px, 1.8em);
            @include mobile {
                margin: 5px auto;
            }
            margin: 5px 0;
        }
        .email {
            @include skeleton-box(200px, 2em);
            @include mobile {
                margin: 0 auto;
                margin-bottom: 16px;
            }
            margin-bottom: 16px;
        }
        .introduce {
            @include skeleton-box(80%, 2.8em);
            @include mobile {
                margin: 0 auto;
            }
        }
    }
}
