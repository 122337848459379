@import '../../styles/lib/mixin';

div.ReactTags__selected {
  padding: 8px 12px;
  background-color: #ebebeb;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 30px;
  margin: 0;
  font-size: $desktop12px;
  width: 100%;
  border: none;
  padding: 0 4px;
  outline: none;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  display: inline-block;
  font-size: $desktop10px;
  font-weight: bold;
  color: #707070;
  background-color: #fff;
  border-radius: 5px;
  padding: 6px 8px;
  margin-bottom: 4px;
  margin-right: 8px;
  vertical-align: middle;
  &:hover {
    background-color: #f9f9f9;
  }
  @include mobile {
    padding: 5px 7px;
    border-radius: 3px;
  }
}

div.ReactTags__selected .ReactTags__remove {
  position: relative;
  color: #000;
  margin-left: 5px;
  font-size: $desktop20px;
  width: 20px;
  line-height: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  vertical-align: middle;
  padding-top: 2px; // 어거지

  &:hover::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0001;
  }
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
