@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

.container {
    @include container();
    .wrapper {
        @include wrapper(0px);
        margin: 40px auto;
    }
}
