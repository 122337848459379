.profile-image {
  width: 100%;
  height: 100%;
  position: relative;

  .image-button {
    width: 100%;
    height: 100%;
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    :global(.MuiIconButton-label) {
      height: 100%;
    }
  }
  .icon {
    position: absolute;
    bottom: -15px;
    right: -15px;
  }
}
