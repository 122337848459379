@import './mixin';

@mixin sign-wrapper() {
    // page display의 sign wrapper(내용) 영역
    width: 100%;
    max-width: $sign-max-width;
    margin: 0 auto;
    padding: 0 $side-space;
    padding-top: 40px;
    @include mobile {
        padding-top: 20px;
    }
}

@mixin sign-note() {
    padding: 20px;
    box-shadow: $base-box-shadow;
    background-color: #fff;
    @include mobile {
        padding: 0;
        box-shadow: none;
        background-color: #f9f9f9;
    }
}

@mixin sign-button-area() {
    margin-top: 20px;
    width: 100%;

    @include mobile {
        margin-top: 0;
        padding: 16px;
        position: fixed;
        left: 0px;
        bottom: $mobile-bottom;
        background: linear-gradient(
            to top,
            #fff,
            // #fffe,
            #fffb,
            #fff8,
            #fff6,
            #fff1,
            #fff0
        );
    }
}

@mixin sign-button($button-color: $main-color, $animate: none) {
    background-color: $button-color;
    width: 100%;
    height: 60px;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: $desktop20px;
    border-radius: $button-border-radius;
    animation: $animate;
    @include mobile {
        height: 48px;
        border-radius: 8px;
    }
    &.disabled {
        background-color: $disabled-button;
        animation: none;
    }
}
