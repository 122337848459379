@import '../../styles/lib/mixin';

.checkbox {
    display: flex;
    align-items: center;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    .box {
        position: relative;
        border: solid 1px #666;
        .check {
            position: absolute;
            left: -2px;
            bottom: 0;
            width: 0px;
            height: 0px;
            background-repeat: no-repeat;
            background-size: cover;
            transition: 0.2s ease-in-out;
        }
    }
    .name {
        margin-left: 10px;
        font-size: $desktop12px;
        font-weight: 500;
        color: #666;
    }
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    & + & {
        margin-left: 40px;
    }
}
