@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

html {
    body {
        .button {
            @include blur-animation();
            letter-spacing: -2px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 104px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: #0003;
            border-radius: 10px;
            font-size: $desktop25px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
            color: #fff;
            font-weight: 500;
            padding: 0 30px;
            &:hover {
                text-decoration: none;
            }
            @include mobile {
                padding: 0 15px;
                height: 70px;
            }
        }
    }
}
