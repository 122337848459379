@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

@mixin default-padding {
  padding: 0 12px;
}
$feed-line-color: #3b3b3b;

.container {
  @include container();
  padding-bottom: 0px;
  .wrapper {
    @include wrapper(0px);
    @include mobile {
      padding: 0;
    }
    .contents {
      @include border-contents();
      background-color: #fff;
      @include mobile {
        padding-bottom: 140px;
      }
      .top-area {
        @include default-padding;
        border-bottom: solid 2px $feed-line-color;
        .title-box {
          display: flex;
          align-items: center;
          width: 100%;
          .support-program {
            @include blur-animation();
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          .title-input {
            width: 100%;
            margin-left: 6px;
            padding-left: 6px;
            background-color: #ebebeb;
            font-size: $desktop24px;
            color: #000;
            font-weight: bold;
            border: none;
            box-sizing: border-box;
          }
        }
        .top-info {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 450px;
          margin-top: 12px;
          padding-bottom: 12px;
        }
      }

      .editor {
        border-bottom: 1px solid #c6c6c6;
        overflow: hidden;
        .preset-view-box {
          margin: 40px 0;
          @include mobile {
            padding: 0 12px;
            margin: 20px 0;
          }
          padding: 0 100px;
        }
      }

      .action-area {
        @include default-padding();
        .upload-area {
          display: flex;
          align-items: center;
          @include mobile {
            margin-bottom: 12px;
          }
          .file-upload {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
          }
          .upload {
            display: flex;
            align-items: center;
            font-weight: bold;
            width: 110px;
            height: 40px;
            border: 1px solid #333;
            background-color: #fff;
            border-radius: 5px;
            font-size: $desktop14px;
          }
          .file-name {
            padding-left: 10px;
            font-size: $desktop14px;
            color: #333;
          }
        }
      }
    }
  }
}
