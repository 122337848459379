@import '../../styles/lib/mixin';

html {
  body {
    .search-hashtag {
      display: flex;
      border: none;
      font-size: $desktop14px;
      font-weight: bold;
      color: #707070;
      background-color: #ececec;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 4px;
      margin-right: 8px;
      &:hover {
        background-color: #dbdbdb;
        text-decoration: none;
      }
      @include mobile {
        padding: 5px 7px;
        border-radius: 3px;
      }
    }
  }
}

.list {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}
