@import '../../styles/lib/mixin';

html {
  body {
    .dialog {
      @include wrapper(40px);
      padding: 0;
      .app-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 500;
        background-color: transparent;
        box-shadow: none;
        .header {
          position: relative;
          @include wrapper(0);
          display: flex;
          align-items: center;
          flex-direction: row;
          background-color: #fff;
          height: 60px;
          padding: 0 24px;
          box-shadow: $base-box-shadow;
          .title {
            font-size: $desktop20px;
            font-weight: 500;
            color: #333;
            @include text-overflow(1);
          }
          .close {
            position: absolute;
            top: 10px;
            right: 12px;
            width: 40px;
            height: 40px;
          }
        }
      }
      .wrapper {
        margin-top: 60px;
        padding: 24px;
        position: relative;
        @include word-content();
      }
    }
  }
}
