@import '../../styles/lib/mixin';

.container {
  @include container();
  .button {
    @include wrapper(0px);
    position: relative;
  }
  .content {
    @include wrapper(20px);
    padding-bottom: 20px;
  }
}
