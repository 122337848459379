@import '../../../styles/lib/sign';

.container {
    @include container();

    .wrapper {
        @include sign-wrapper();
        .note {
            @include sign-note();
        }
    }
}
