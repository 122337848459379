@import '../../styles/lib/mixin';

$mobile-filter-space: 120px;

.container {
  @include container();

  .wrapper {
    @include wrapper(0px);

    &.search {
      padding-top: 20px;
    }
    &.mobile-none {
      position: relative;
      @include mobile {
        display: none;
      }
    }
    &.filter {
      position: relative;
      @include mobile {
        background-color: #f9f9f9;
        position: fixed;
        z-index: 100;
        bottom: 60px;
        left: 0;
        transform: translateY(100%);
        transition: all 0.2s ease-in-out;
        padding-bottom: $mobile-filter-space;
        box-shadow: $base-box-shadow;
        .filter-open {
          .button-image {
            width: 12px;
            transform: rotate(270deg);
          }
        }
        &.mobile-view {
          transform: translateY($mobile-filter-space);
          .filter-open {
            .button-image {
              transform: rotate(90deg);
            }
          }
        }
      }
      .box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 15px 0;
        border-bottom: 1px solid #e5e5e5;
        @include mobile {
            border-bottom: none;
        }
        .filter-open {
          visibility: hidden;
          position: absolute;
          top: -32px;
          left: 50%;
          @include mobile {
            visibility: visible;
            transform: translateX(-50%);
            width: 48px;
            height: 48px;
            background-color: #fff;
            box-shadow: $base-box-shadow;
            z-index: 400;
          }
        }
        .filter-area {
          @include mobile {
            width: 100%;
          }
        }
        select {
          cursor: pointer;
          min-width: 110px;
          height: 40px;
          border: 1px solid #333;
          border-radius: 5px;
          padding: 0 24px;
          font-weight: bold;
          background-position: calc(100% - 5px) 50%, 5px 50%;
          background-repeat: no-repeat;
          background-color: #fff;
          @include mobile {
            width: 100%;
            height: 30px;
          }

          &.model {
            background-image: url('../../images/svg/selectbox/arrow.svg'),
              url('../../images/svg/selectbox/model/models2.svg');
            &.Grid10 {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/model/grid10.svg');
            }
          }
          &.os {
            background-image: url('../../images/svg/selectbox/arrow.svg'),
              url('../../images/svg/selectbox/os/os.svg');
            &.Public {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/os/public.svg');
            }
            &.Mac {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/os/mac.svg');
            }
            &.Windows {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/os/windows.svg');
            }
          }
          &.program {
            background-image: url('../../images/svg/selectbox/arrow.svg'),
              url('../../images/svg/selectbox/program/programs.svg');
          }
          &.sort {
            border: solid #ebebeb;
            color: rgba(0, 0, 0, 0.4);
            background-image: url('../../images/svg/selectbox/arrow.svg'),
              url('../../images/svg/selectbox/filter/recently.svg');
            &.hit {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/filter/hit.svg');
            }
            &.download_hit {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/filter/download_hit.svg');
            }
            &.like {
              background-image: url('../../images/svg/selectbox/arrow.svg'),
                url('../../images/svg/selectbox/filter/hit.svg');
            }
          }
          &:not(:first-child) {
            margin-left: 10px;
          }
          @include mobile {
            margin-left: 0 !important;
            margin-top: 10px;
          }
        }
      }
    }
    &.contents {
      padding-top: 30px;
      // ! 푸터와 간격 주는 부분
      padding-bottom: 50px;
    }
  }
}
