@import './styles/lib/mixin';

@font-face {
  font-family: 'KoPub World Dotum';
  font-style: normal;
  font-weight: normal;
  src: local('KoPub World Dotum'), local('KoPubWorldDotum'),
    url('./styles/fonts/KoPubWorldDotumLight.ttf') format('truetype');
}
@font-face {
  font-family: 'KoPub World Dotum';
  font-style: normal;
  font-weight: 500;
  src: local('KoPub World Dotum'), local('KoPubWorldDotum'),
    url('./styles/fonts/KoPubWorldDotumMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'KoPub World Dotum';
  font-style: normal;
  font-weight: bold;
  src: local('KoPub World Dotum'), local('KoPubWorldDotum'),
    url('./styles/fonts/KoPubWorldDotumBold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'KoPub World Dotum';
  box-sizing: border-box;
}
html,
body {
  background-color: #f9f9f9;
  font-size: $root-font-size-desktop;
  @include tablet {
    font-size: $root-font-size-tablet;
  }
  @include mobile {
    font-size: $root-font-size-mobile;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
input,
select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  outline: none;
  &::-ms-expand {
    display: none;
  }
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

mark {
  background-color: yellow;
}

img {
  @include block-download();
}
