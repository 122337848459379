@import '../../styles/lib/mixin';

.container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5000;
    transition: all .3s ease-in-out;
    opacity: 0;
    &.isRefresh {
        top: $desktop-top + 12;
        opacity: 1;
        @include mobile {
            top: $mobile-top;
        }
    }
    .refresh {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.32);
    }
}