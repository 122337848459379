@import '../../styles/lib/mixin';

.empty {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  font-size: $desktop17px;
  font-weight: 500;
}

.profile {
  margin-top: 10px;
  .profile-image {
    width: 30px;
    height: 30px;
  }
}

.list {
  margin-top: 16px;
  .item {
    width: 100%;
    .update-area {
      padding-bottom: 8px;
      border-bottom: solid 2px $main-color;
    }
    .parent {
      display: flex;
      padding: 0 12px;
      &.new {
        border-left: solid 3px $main-color;
      }
      .comment {
        position: relative;
        margin-left: 12px;
        padding-right: 40px;
        .main-content {
          background-color: #f0f2f5;
          border-radius: 10px;
          min-width: 210px;
          max-width: 100%;
          min-height: 60px;
          overflow: hidden;
          padding: 10px 12px;
          .nickname {
            font-size: $desktop17px;
            font-weight: bold;
            @include text-overflow(1);
          }
          .content {
            font-size: $desktop15px;
          }
        }
        .info {
          display: flex;
          justify-content: space-between;
          margin-top: 4px;
          width: 100%;
          padding: 0 16px;

          .reply,
          .created-at {
            color: #65676b;
            font-size: $desktop12px;
          }
          .reply {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .more-info {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 40px;
          height: 40px;
        }
      }
    }
    .child-area {
      margin-top: 16px;
      padding-left: 40px;
      @include mobile {
        padding-left: 20px;
      }
      .reply-area {
        padding-bottom: 8px;
        border-bottom: solid 2px $main-color;
        @include mobile {
          padding-bottom: 4px;
        }
      }
      .child-list {
        margin-top: 8px;
      }
    }
  }
}

.item {
  & + & {
    margin-top: 16px;
  }
}
