@import '../../styles/lib/mixin';

.list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
    .profile {
        display: flex;
        width: calc(50% - 10px);
        height: 140px;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #fff;

        transition: .5s ease-in-out;
        transition-property: box-shadow, transform;
        &.onload {
            box-shadow: $base-box-shadow;
        }
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.32);
        }
        @include mobile {
            width: 100%;
            height: 120px;
            margin-bottom: 12px;
        }
        .image-view {
            display: flex;
            align-items: center;
            .profile-image {
                position: relative;
                width: 120px; height: 120px;
                @include mobile {
                    width: 80px; height: 80px;
                }
            }
        }
        .info {
            position: relative;
            width: 100%;
            margin-left: 20px;
            overflow: hidden;

            .nickname {
                font-size: $desktop18px;
                @include text-overflow(1);
            }
            .name {
                font-size: $desktop13px;
                color: #555;
                @include text-overflow(1);
            }
            .email {
                font-size: $desktop15px;
                font-weight: 500;
                color: #333;
                margin-bottom: 16px;
                @include text-overflow(1);
            }
            .introduce {
                font-size: $desktop16px;
                font-weight: 500;
                line-height: 1.5;
                color: #333333;
                @include text-overflow(1);
            }
        }
    }
}
