@mixin animation($delay, $name) {
    animation: $name 0.2s linear;
}

@mixin jump-animation($delay: 5s, $duration: 10s, $iteration-count: infinite) {
    // 점프 애니메이션
    @keyframes jump {
        0% {
            transform: translate(0, 0) scale(1, 0.75);
        }
        5% {
            transform: translate(0, -65%) scale(1, 1.25);
        }
        6% {
            transform: translate(0, -65%);
        }
        7% {
            transform: translate(0, -65%) scale((1, 1.25));
        }
        10% {
            transform: translate(0, 0) scale(1, 0.75);
        }
        15% {
            transform: translate(0, -65%) scale(1, 1.25);
        }
        16% {
            transform: translate(0, -65%);
        }
        17% {
            transform: translate(0, -65%) scale((1, 1.25));
        }
        20% {
            transform: translate(0, 0) scale(1, 1);
        }
    }

    display: inline-block;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-name: jump;
    animation-iteration-count: $iteration-count;
    // animation-direction: alternate;

    &:hover {
        animation: none;
    }
}

@mixin vibration-animation {
    // 진동 애니메이션
    @keyframes vibration {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(-0.75%, 0);
        }
        50% {
            transform: translate(0, 0);
        }
        75% {
            transform: translate(0.75%, 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }

    animation: vibration 0.1s none 0s infinite;
}

@mixin blur-animation($duration: 0.3s) {
    @keyframes blur {
        from {
            filter: blur(12px);
        }
        to {
            filter: blur(0);
        }
    }
    animation: blur $duration linear;
}