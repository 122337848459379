@import '../../styles/lib/sign';

.container {
    @include container();

    .wrapper {
        @include sign-wrapper();
        padding-bottom: 12px;
        .note {
            @include sign-note();
        }

        .link {
            color: #0066ff;
        }
        .form-box {
            margin-top: 30px;
            font-size: $desktop15px;
            .auto-check {
                margin-top: 12px;
            }
        }

        .signin-button {
            width: 100%;
            height: 60px;
            border: none;
            border-radius: 10px;
            background-color: #ebebeb;
            font-size: $desktop17px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 25px;
            margin-top: 44px;
            transition: all 0.3s ease-in-out;

            &.on {
                background: #0066ff;
            }
        }

        .social {
            margin-top: 40px;
        }
    }
}
