html {
    body {
        .representative-icon {
            display: flex;
            align-items: center;
            width: 60px;
            height: 60px;
            padding: 0;
        }
    }
}
