@import '../../styles/lib/mixin';

.dialog {
  position: relative;
  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #222;
    .close {
      position: absolute;
      top: 10px;
      right: 12px;
    }
    .image-area {
      width: 600px;
      height: 600px;
      @include notebook {
        width: 400px;
        height: 400px;
      }
      @include tablet {
        width: 300px;
        height: 300px;
      }
      @include mobile {
        width: 200px;
        height: 200px;
      }
    }
  }
}
