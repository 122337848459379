@import '../../styles/lib/mixin';

.link {
    .box {
        position: relative;
        display: block;
        width: 100%;
        height: 100px;
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        padding: 5px;
        transition: all 0.3s ease-in-out;
        letter-spacing: -1px;

        @include mobile {
            height: 80px;
        }
        &:hover {
            border: 1px solid #0066ff;
            color: #0066ff;
            transform: scale(1.05);
            text-decoration: none;
        }

        .text {
            font-size: $desktop28px;
        }
        .descript {
            font-size: $desktop15px;
            margin-top: 12px;
            @include mobile {
                margin-top: 8px;
            }
        }
    }
}

.box {
    & + & {
        margin-top: 16px;
    }
}


// find id / pw

.find-method-box {
    margin-bottom: 40px;
}
.phone-auth {
    margin-top: 20px;
}