@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

$feed-line-color: #3b3b3b;

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }
  .feed {
    width: calc(50% - 10px);
    margin-bottom: 20px;

    transition: 1s ease-in-out;
    transition-property: box-shadow, transform;

    transform: scale(0.99) translateY(20px);

    @include feed-colorful();

    &.onload {
      box-shadow: $base-box-shadow;
      transform: none;
    }
    // &:hover {
    //   @include none-mobile {
    //     transform: scale(1.01) translateY(-10px);
    //     box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.32);
    //   }
    // }

    @include mobile {
      width: 100%;

      .feed-title {
        width: 70% !important;
      }
    }
    .feed-wrapper {
      position: relative;
      padding: 10px;
      overflow: hidden;
      .feed-content {
        width: 100%;
        height: 100%;
        .top-area {
          border-bottom: solid 2px $feed-line-color;
          margin-bottom: 12px;
          .title-area {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: $desktop16px;
            .support-program {
              @include blur-animation();
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }
            .title {
              margin-left: 12px;
              width: 100%;
              @include text-overflow(1);
            }
            .program-image-area {
              width: 40px;
              height: 40px;
            }
          }
          .top-info {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 0;
            .info-item {
              width: 25%;
              padding-right: 10px;
              color: $feed-line-color;
              font-size: $desktop12px;
              .info-name {
                font-weight: lighter;
              }
              .info-value {
                @include text-overflow(1);
                font-weight: bold;
              }
            }
          }
        }
        .feed-content-box {
          width: 100%;
          @include text-overflow(5);
          .feed-text {
            * {
              font-size: $desktop16px;
            }
          }
        }
        .bottom-info {
          width: 100%;
          .hashtag-box {
            width: 100%;
            margin: 10px 0;
            overflow: hidden;
            @include text-overflow(1);
          }
          .feed-interaction {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-top: 12px;
            border-top: solid 1px $feed-line-color;
            align-items: center;
            .feed-check {
              display: flex;
            }
            .feed-button-area {
              display: flex;
              .feed-button {
                min-width: 40px;

                &.download {
                  @include mobile {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .screen {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.32);
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        .hide-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 20;
          font-size: $desktop18px;
          color: #fff;
        }
      }
    }
  }
  .link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
