@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.profile {
    @include skeleton-wrapper(auto, 10px);
    display: flex;
    width: calc(50% - 10px);
    margin-bottom: 20px;
    @include mobile {
        width: 100%;
        height: 120px;
        margin-bottom: 12px;
    }
    .image-view {
        display: flex;
        align-items: center;
        .profile-image {
            width: 120px; height: 120px;
            @include mobile {
                width: 80px; height: 80px;
            }
        }
    }
    .info {
        width: 100%;
        margin-left: 20px;
        .nickname {
            @include skeleton-box(60%, 2em);
        }
        .name {
            @include skeleton-box(100px, 1.5em);
            margin: 5px 0;
        }
        .email {
            @include skeleton-box(200px, 1.8em);
            margin-bottom: 16px;
        }
        .introduce {
            @include skeleton-box(80%, 2em);
        }
    }
}
