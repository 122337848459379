@import '../../styles/lib/mixin';

.mobile-header {
    position: fixed;
    padding: 0 24px;
    top: 0;
    left: 0;
    height: 48px;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    z-index: 1000;
    display: none;
    transition: all 0.5s;

    &.headerOff {
        top: -48px;
    }
    @include mobile {
        display: block;
    }
    .title {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 48px;
    }
}
html {
    body {
        .back {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
        }
    }
}
