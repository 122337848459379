@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

html {
  body {
    .note {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      padding: 10px;
      width: 100%;
      height: 170px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      text-decoration: none;
      background-color: #fff;

      @include mobile {
        height: 100px;
      }

      .thumbnail {
        @include blur-animation();
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 150px;
        max-width: 150px;
        height: 100%;
        padding: 20px;
        border-radius: 10px;
        background-color: #000;

        @include mobile {
          min-width: 80px;
          max-width: 80px;
          height: 100%;
          padding: 10px;
        }
        .thumbnail-logo {
          width: 100%;
        }
      }

      .content-box {
        margin-left: 12px;
        width: 100%;
        overflow: hidden;

        .title {
          @include text-overflow(1);
          font-size: $desktop17px;
        }

        .writer {
          @include text-overflow(1);
          margin-top: 8px;
          font-size: $desktop12px;
          color: #666;
          @include mobile {
            margin-top: 4px;
          }
        }

        .content {
          @include text-overflow(3);
          margin-top: 16px;
          color: #666;
          * {
            font-size: $desktop16px;
          }
          ul {
            list-style: square;
            padding-left: 20px;
          }
          @include mobile {
            font-size: $desktop15px;
            margin-top: 12px;
            @include text-overflow(1);
          }
        }
      }
    }
  }
}

.note {
  & + & {
    margin-top: 16px;
    @include mobile {
      margin-top: 12px;
    }
  }
}
