@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';
@import '../../styles/lib/animation';

.container {
  @include container();
  .wrapper {
    @include wrapper(40px);
    .title-bar {
      .title {
        font-size: $desktop30px;
        word-break: keep-all;
        letter-spacing: -1px;
      }
      .created-at {
        color: #777;
        font-weight: 500;
        font-size: $desktop12px;
      }
    }
    .thumbnail {
      @include blur-animation();
      width: 100%;
      background-color: #000;
      height: 100px;
      border-radius: 15px;
      margin: 16px 0;
      text-align: center;
      @include mobile {
        height: 70px;
      }
      .logo {
        width: 250px;
        height: 100%;
        @include mobile {
          width: 150px;
        }
      }
    }
    .content {
      @include word-content();
    }
  }
}

.skeleton-title-bar {
  @include skeleton-wrapper(auto, 5px);
  .skeleton-title {
    @include skeleton-box(100%, 40px);
  }
  .skeleton-created-at {
    margin-top: 5px;
    @include skeleton-box(200px, 20px);
  }
}

.skeleton-content {
  @include skeleton-wrapper(auto, 10px);
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    @include skeleton-box(100%, 450px);
  }
}
