@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';


.skeleton-search-hashtag {
    @include skeleton-wrapper(40px, 5px);
    width: 60px;
    margin-bottom: 4px;
    margin-right: 8px;
    &::after {
        content: '';
        display: block;
        @include skeleton-box(100%, 100%);
        box-sizing: border-box;
    }
    @include mobile {
        height: 26px;
        padding: 5px 7px;
        border-radius: 3px;
    }
}
