.dimmed {
    position: fixed;
    z-index: 90;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    transition: opacity .2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
    &.force {
        transition: none;
    }
}
