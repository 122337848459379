@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.skeleton-qna {
    @include skeleton-wrapper(60px, 10px);
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;

    & + & {
        margin-top: 16px;
        @include mobile {
            margin-top: 12px;
        }
    }
    @include mobile {
        height: 48px;
    }
    .title {
        @include skeleton-box(50%, 100%);
        margin-right: 20px;
        @include mobile {
            margin-right: 10px;
        }
    }
    .created-at {
        @include skeleton-box(120px, 100%);
        margin-right: 20px;
        @include mobile {
            margin-right: 10px;
        }
    }
    .status {
        @include skeleton-box(80px, 100%);
    }
}
