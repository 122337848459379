@import '../../styles/lib/mixin';

.footer {
    letter-spacing: -0.5px;
    transform: skew(-0.03deg);
    background-color: #efefef;
    width: 100%;
    height: 400px;

    @include mobile {
        display: none;
    }
    .wrapper {
        @include wrapper(0px);
        text-align: center;

        .logo {
            width: 137px;
            height: 20px;
            margin-top: 40px;
        }

        .nav {
            margin-top: 30px;

            & ul {
                & li {
                    display: inline-block;
                    margin-right: 53px;

                    &:last-child {
                        margin: 0;
                    }

                    .link {
                        font-size: $desktop16px;
                        font-weight: bold;
                    }
                }
            }
        }

        .sns_box {
            margin-top: 20px;

            & img {
                width: 30px;
                height: 30px;
                margin: 0 10px;
            }
        }

        .contact_box {
            margin-top: 20px;

            & p {
                font-size: $desktop26px;
                margin-bottom: 1%;

                & span {
                    font-size: $desktop26px;

                    font-weight: bold;
                }
            }
        }
        .companyInfo {
            font-size: $desktop12px;
            line-height: 2;
        }
    }
}

.policy {
    margin: 10px 0;
    .link {
        color: #333;
        margin: 0 5px;
        font-size: 15px;
        letter-spacing: -1px;
    }
}
