@import '../../styles/lib/mixin';

/* ! Quill CSS 직접 수정 */

.ql-editor {
  @include word-content;
  padding: 12px;
  min-height: 200px;
  @include mobile {
    min-height: 150px;
  }
}

.ql-toolbar.ql-snow {
  border: none;
  white-space: nowrap;
}
.ql-wrapper {
  overflow-y: visible;
}
.ql-container.ql-snow {
  border: none;
  background-color: #ebebeb;
}

.ql-tooltip {
  left: 0 !important;
  top: 32px !important;
}
