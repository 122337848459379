@import '../../styles/lib/mixin';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    .error-text {
        font-size: $desktop24px;
        color: $disabled-font-color;
    }
}
