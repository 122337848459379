@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.skeleton-item {
    @include mobile {
        width: 100%;
        max-width: none;
    }
    @include skeleton-wrapper(350px, 10px);
    width: calc(50% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;

    .skeleton-title-area {
        display: flex;
        .skeleton-image {
            @include skeleton-box(40px, 40px);
        }
        .skeleton-title {
            margin-left: 12px;
            @include skeleton-box(200px, 40px);
        }
    }
    .skeleton-content {
        margin-top: 12px;
        @include skeleton-box(100%, 230px);
    }
    .skeleton-bottom {
        margin-top: 12px;
        @include skeleton-box(100%, 36px);
    }
}
