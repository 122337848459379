@import '../../styles/lib/mixin';

.swipe {
  display: none;
  @include mobile {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 300;
  }
}
