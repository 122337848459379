// responsive size
$breakpoint-mobile: 0px;
$breakpoint-tablet: 768px;
$breakpoint-notebook: 1024px;
$breakpoint-desktop: 1280px;

// display
$mobile-top: 48px;
$mobile-bottom: 60px;

$desktop-top: 60px;
$desktop-bottom: 60px;

$side-space: 24px;

$sign-max-width: 524px + ($side-space * 2); // 여백만큼 추가
$max-width: 852px + ($side-space * 2); // 여백만큼 추가
$min-width: 320px;
$content-width: 420px;

$standard-border: solid 1px;

$button-border-radius: 10px;
$base-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);

// color
$main-color: #0066ff;
$main-tonedown: #0044dd;
$disabled-font-color: #8b8b8b;
$disabled-color: #ebebeb;
$disabled-button: #dbdbdb;

// font
$root-font-size-desktop: 12px;
$root-font-size-tablet: 11px;
$root-font-size-mobile: 10px;

$desktop10px: 0.8333rem;
$desktop11px: 0.9167rem;
$desktop12px: 1rem;
$desktop13px: 1.083rem;
$desktop14px: 1.167rem;
$desktop15px: 1.25rem;
$desktop16px: 1.333rem;
$desktop17px: 1.417rem;
$desktop18px: 1.5rem;
$desktop20px: 1.667rem;
$desktop22px: 1.833rem;
$desktop24px: 2rem;
$desktop25px: 2.083rem;
$desktop26px: 2.167rem;
$desktop28px: 2.333rem;
$desktop30px: 2.5rem;
$desktop32px: 2.667rem;
$desktop34px: 2.833rem;
$desktop42px: 3.5rem;