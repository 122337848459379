@import '../../styles/lib/mixin';

.list {
    @include interval-between('.item') {
        margin-top: 16px;
        @include mobile {
            margin-top: 12px;
        }
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        padding: 0 16px;
        font-size: $desktop14px;
        font-weight: bold;
        text-decoration: none;
        white-space: nowrap;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: #fff;

        transition: background-color 0.3s;
        
        &:hover {
            background-color: #f0f0f0;
        }
        @include mobile {
            height: 40px;
        }
    }
}

.title {
    color: #222;
    @include text-overflow(1);
    width: 50%;
    margin-right: 20px;
    @include mobile {
        margin-right: 10px;
    }
}
.created-at {
    color: #777;
    width: 120px;
    margin-right: 20px;
    text-align: right;
    @include mobile {
        margin-right: 10px;
    }
}
.status {
    color: #777;
    width: 80px;
    text-align: right;
    &.on {
        color: $main-color;
    }
}

.head-bar {
    border-top: 1px solid #ebebeb;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    font-size: $desktop14px;
    font-weight: 500;

    @include mobile {
        border-top: none;
        border-bottom: 1px solid #ebebeb;
        padding: 8px 16px;
        margin-bottom: 16px;
    }
}
