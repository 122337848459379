@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.comment {
  display: flex;

  & + & {
    margin-top: 16px;
  }
  .image-view {
    display: flex;
    .profile-image {
      width: 30px;
      height: 30px;
      margin-top: 10px;
    }
  }
  .content {
    @include skeleton-wrapper(auto, 10px);
    margin-left: 12px;
    .name {
      @include skeleton-box(180px, 24px);
    }
    .text {
      margin-top: 8px;
      @include skeleton-box(100%, 20px);
    }
    .created-at {
      margin-top: 8px;
      @include skeleton-box(80px, 16px);
    }
  }
  .more-area {
    .more {
      width: 40px;
    }
  }
}
