@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.skeleton-note {
    @include skeleton-wrapper(170px, 10px);
    display: flex;

    & + & {
        margin-top: 16px;
        @include mobile {
            margin-top: 12px;
        }
    }
    @include mobile {
        height: 100px;
    }
    .thumbnail {
        @include skeleton-box(150px, 100%);
        min-width: 150px;
        max-width: 150px;
        @include mobile {
            min-width: 80px;
            max-width: 80px;
        }
    }
    .content-box {
        margin-left: 12px;
        width: 100%;
        .title {
            @include skeleton-box(50%, 20px);
        }
        .writer {
            margin-top: 8px;
            @include skeleton-box(35%, 15px);
        }
        .content {
            @include skeleton-box(100%, calc(100% - 55px));
            margin-top: 12px;
        }
    }
}
