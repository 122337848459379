@import '../../../styles/lib/mixin';
@import '../../../styles/lib/animation';

@mixin icon-image($overflow: false) {
  @if $overflow == true {
    overflow: hidden;
  }
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 0;
}

.layout {
  display: flex;
  flex-direction: column;

  .flex-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .flex-item {
      width: calc(50% - 10px);
    }
  }
  // 프로필 변경
  .division {
    &.row {
      display: flex;
    }
    .area {
      width: 100%;
      .profile-image {
        @include icon-image(false);
        .change-profile {
          display: none;
        }
      }
      .button {
        margin-top: 10px;
        padding: 2px 8px;
        border-radius: 4px;
        background-color: #222;
        color: #fff;
        font-weight: bold;
        font-size: $desktop12px;
      }
    }
  }
  &.secession {
    &.checked {
      & :not(div) {
        // @include vibration();
      }
    }
    .caution {
      background-color: #ececec;
      padding: 40px 10px;
      margin-bottom: 24px;
      text-align: center;
      word-break: keep-all;
      .attention {
        font-size: $desktop22px;
        color: #222;
        font-weight: 500;
        margin-bottom: 24px;
      }
      .warning {
        font-size: $desktop14px;
        color: #666;
      }
    }
  }
}

.warning {
  & + & {
    margin-top: 8px;
  }
}

.division {
  & + & {
    margin-top: 20px;
  }
}
