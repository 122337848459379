@import '../../../styles/lib/sign';

.write {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  @include sign-button-area();
  @include mobile {
    height: auto;
    display: block;
  }
  .button {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100px;
    height: 100%;
    background-color: $main-color;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: $desktop16px;
    transform: translateY(-50%);

    transition: all 0.15s;

    @include mobile {
      position: relative;
      top: 0;
      transform: none;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      font-size: $desktop20px;
    }
    &:hover {
      background-color: $main-tonedown;
      box-shadow: $base-box-shadow;
    }
    &.disabled {
      background-color: $disabled-color;
    }
  }
}
