@import './variables';

@mixin mobile {
  // 모바일에서만 사용하는 쿼리
  @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  // 태블릿에서만 사용하는 쿼리
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-notebook - 1px}) {
    @content;
  }
}
@mixin notebook {
  // 노트북에서만 사용하는 쿼리
  @media (min-width: #{$breakpoint-notebook}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}
@mixin desktop {
  // 데스크탑에서만 사용하는 쿼리
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin all-mobile {
  // 데스크탑 제외하고 사용하는 쿼리
  @media screen and (max-width: $breakpoint-notebook) {
    @content;
  }
}

@mixin none-mobile {
  // 모바일 제외하고 사용하는 쿼리
  @media screen and (min-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin text-overflow($lines) {
  // 텍스트를 $lines 만큼만 보여주고 ellipsis 처리.
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  @if $lines > 1 {
    display: -ms-flexbox;
    display: -webkit-inline-box;
    display: -webkit-box;
    -webkit-line-clamp: $lines; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 24px;
    height: 24px * $lines;
    // height: 1.5em * $lines;
    @include mobile {
      line-height: 20px;
      height: 20px * $lines;
    }
  } @else {
    white-space: nowrap;
    height: 1.5em;
  }
}

@mixin container {
  // page display의 container 영역
  width: 100%;
  padding-top: $desktop-top;
  padding-bottom: $desktop-bottom;
  min-height: 100vh;
  @include mobile {
    padding-top: $mobile-top;
    padding-bottom: $desktop-bottom;
  }
}

@mixin wrapper($padding-top) {
  // page display의 wrapper(내용) 영역
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $side-space;
  padding-top: $padding-top;
}

@mixin border-contents() {
  // page display에서 세로 선이 있는 내용 영역
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 12px 0;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

@mixin interval-between($selector) {
  @at-root #{$selector} {
    & + & {
      @content;
    }
  }
}

@mixin title {
  // page title
  font-size: $desktop42px;
  font-weight: bold;
  color: #222;
  letter-spacing: -2px;

  @include mobile {
    font-size: $desktop30px;
  }
}

@mixin sub-title {
  font-size: $desktop30px;
  font-weight: bold;
  word-break: keep-all;
  letter-spacing: -1px;
  color: #222;
}

@mixin word-content {
  line-height: 1.5;
  font-size: $desktop18px;
  ul {
    list-style: square;
    margin: 15px 0;
    padding-left: 40px;
  }
  h1 {
    font-size: 2em;
  }
  img {
    width: 100%;
  }
}

@mixin border-input {
  border-color: #e5e5e5;
  transition: all 0.15s;
  &:focus {
    border-color: $main-color;
  }
}

@mixin block-download {
  -webkit-touch-callout: none;
  pointer-events: none;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

@mixin feed-colorful {
  background-color: #fff;
  background-image: url('../../images/feed/feed-back.png');
  &.Background {
    background-color: #13181e05;
  }
  &.Photoshop {
    background-color: #2da9ff05;
  }
  &.LightroomClassic {
    background-color: #2da9ff05;
  }
  &.Illustrator {
    background-color: #fd9b0005;
  }
  &.PremierePro {
    background-color: #89a6ff05;
  }
  &.AfterEffect {
    background-color: #89a6ff05;
  }
  &.XD {
    background-color: #fc64ff05;
  }
  &.InDesign {
    background-color: #fc327d05;
  }
  &.Excel {
    background-color: #3ee6a505;
  }
  &.PowerPoint {
    background-color: #fd988405;
  }
  &.Word {
    background-color: #30bcff05;
  }
  &.ClipStudio {
    background-color: #2a313b05;
  }
  &.Hangeul {
    background-color: #20a7ff05;
  }
}
