@import '../../styles/lib/sign';
@import '../../styles/lib/skeleton';

.container {
  @include container;
  .wrapper {
    @include wrapper(40px);
    @include mobile {
      padding-bottom: 130px;
    }
    .answer {
      margin-top: 50px;
      border: 2px solid #777;
      padding: 10px;
    }
    .title-bar {
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 40px;
      border-bottom: 1px solid #ebebeb;
      @include mobile {
        margin-bottom: 20px;
      }
      .title {
        font-size: $desktop30px;
        word-break: keep-all;
        letter-spacing: -1px;
      }
      .created-at {
        color: #777;
        font-weight: 500;
        font-size: $desktop12px;
      }
      .status {
        width: 60px;
        height: 30px;
        background-color: #333;
        color: #fff;
        text-align: center;
        line-height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        &.on {
          background-color: $main-color;
        }
      }
    }
    .content {
      @include word-content();
      padding: 10px 15px;
      min-height: 450px;
    }
    .button-area {
      display: flex;
      justify-content: flex-end;
      @include sign-button-area();
      @include mobile {
        display: block;
      }
      .button {
        width: 100px;
        height: 40px;
        font-weight: bold;
        font-size: $desktop16px;
        border-radius: 4px;

        transition: all 0.15s;

        @include mobile {
          width: 100%;
        }
        &:hover {
          box-shadow: $base-box-shadow;
        }
        &.update {
          color: #fff;
          background-color: #222;
        }
        &.delete {
          border: 2px solid;
          background-color: #fff;
          color: #222;
          margin-left: 5px;
          @include mobile {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }
  }
}

.skeleton-title-bar {
  @include skeleton-wrapper(auto, 5px);
  margin-bottom: 40px;
  @include mobile {
    margin-bottom: 20px;
  }
  .skeleton-title {
    @include skeleton-box(70%, 40px);
  }
  .skeleton-created-at {
    margin-top: 5px;
    @include skeleton-box(200px, 20px);
  }
  .skeleton-status {
    @include skeleton-box(60px, 30px);
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.skeleton-content {
  @include skeleton-wrapper(auto, 10px);
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    @include skeleton-box(100%, 450px);
  }
}

.skeleton-button-area {
  @include skeleton-wrapper(100%, 5px);
  display: flex;
  justify-content: flex-end;

  @include mobile {
    display: block;
    height: auto;
  }
  .skeleton-button {
    @include skeleton-box(100px, 40px);
    @include mobile {
      width: 100%;
    }
    &:last-child {
      margin-left: 5px;
      @include mobile {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}
