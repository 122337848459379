@import '../../../styles/lib/mixin';

.sub-title {
    font-size: $desktop15px;
    font-weight: 500;
    color: #666;
}

.reset-input-box {
    margin-top: 40px;
}
