@import '../../styles/lib/sign';
@import '../../styles/lib/animation';

html {
    body {
        .box {
            @include sign-button-area();
            .button {
                @include sign-button(#f00);
                @include vibration-animation();
            }
        }
    }
}
