@import '../../styles/lib/mixin';

.container {
    @include container();

    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        @include wrapper(0px);
        font-size: $desktop42px;
        font-weight: bold;
        color: #222;
        text-align: center;
        @include mobile {
            font-size: $desktop30px;
        }
    }
}
