@import '../../styles/lib/mixin';

html {
    body {
        .tabs {
            width: 100%;
            display: flex;
            border-bottom: 1px solid #ebebeb;
            background-color: #fff;
        }
        .item {
            width: 100%;
            height: 70px;
            svg {
                transition: all 0.3s ease-in-out;
            }
            .tab-name {
                @include mobile {
                    display: none;
                }
                font-size: 17px;
                color: #9e9e9e;
                padding: 0 15px;
                box-sizing: border-box;
                &.on {
                    color: #0066ff;
                }
            }
        }
    }
}

html {
    body {
        @include mobile {
            .tabs {
                border-top: 1px solid #ebebeb;
            }
            .item {
                height: 45px;
            }
        }
    }
}
