@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';
@import '../../styles/lib/skeleton';

@mixin default-padding {
  padding: 0 12px;
}
$feed-line-color: #3b3b3b;

.container {
  @include container();
  padding-bottom: 0px;
  .wrapper {
    @include wrapper(0px);
    @include mobile {
      padding: 0;
    }
    .contents {
      @include border-contents();
      padding-bottom: 0px;

      @include feed-colorful();
      .top-area {
        @include default-padding;
        border-bottom: solid 2px $feed-line-color;
        .title-box {
          display: flex;
          align-items: center;
          width: 100%;
          .support-program {
            @include blur-animation();
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }
          .title {
            margin-left: 12px;
            font-size: $desktop24px;
          }
          .program-image-area {
            width: 40px;
            height: 40px;
          }
        }
        .top-info {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 450px;
          margin-top: 12px;
          padding-bottom: 12px;
        }
      }
    }

    .feed-content-box {
      width: 100%;
      padding-top: 40px;
      .text-contents {
        @include word-content;
        padding: 12px;
        min-height: 200px;
        @include mobile {
          min-height: 150px;
        }
      }
    }

    .preset-view-box {
      margin: 40px 0;
      @include mobile {
        padding: 0 12px;
        margin: 20px 0;
      }
      padding: 0 100px;
    }

    .hashtag-box {
      @include default-padding;
      display: flex;
      flex-wrap: wrap;

      @include mobile {
        margin: 0;
      }
    }

    .contents-data-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 12px;

      .contents-info {
        font-size: $desktop12px;
        color: #707070;
      }
    }

    .feed-interaction {
      position: sticky;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      width: 100%;
      height: 50px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
      align-items: center;
      @include feed-colorful();

      @include mobile {
        bottom: 60px;
      }
      .feed-check {
        display: flex;
      }
      .feed-button-area {
        display: flex;
        .feed-button {
          min-width: 40px;
          &.download {
            @include mobile {
              display: none;
            }
          }
        }
        .more-info {
          width: 40px;
          height: 40px;
          text-align: center;
          position: relative;
        }
      }
    }
    .comment-area {
      padding: 20px 0;
      width: 100%;
      background-color: #fff;
      .comment-focus {
        margin-top: 12px;
        padding: 0 12px;
        .comment-positioner {
          color: #777;
          font-size: $desktop14px;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.skeleton-title-bar {
  @include skeleton-wrapper(auto, 5px);
  display: flex;
  .skeleton-program-image {
    @include skeleton-box(40px, 40px);
    min-width: 40px;
    max-width: 40px;
  }
  .skeleton-title {
    margin-left: 12px;
    @include skeleton-box(100%, 40px);
  }
}

.skeleton-content {
  @include skeleton-wrapper(300px, 10px);
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    @include skeleton-box(100%, 100%);
  }
}
