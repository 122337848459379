@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

.button {
    margin-top: 48px;
    background: #0066ff;
    border: none;
    border-radius: 10px;
    @include mobile {
        margin-top: 24px;
    }
}

.container {
    @include container();
    @include mobile {
        padding-top: 24px;
    }
    .wrapper {
        @include wrapper(0px);

        &.profile {
            padding-top: 40px;
            @include mobile {
                padding-top: 0px;
            }
        }
        &.logout {
            display: none;
            @include mobile {
                margin-top: 24px;
                display: flex;
                width: 100%;
                justify-content: center;
            }
            .button {
                width: 80px; height: 32px;
                border-radius: 5px;
                background-color: $main-color;
                font-size: $desktop14px;
                font-weight: bold;
                color: #fff;
            }
        }
        &.tabs {
            position: sticky;
            top: 60px;
            z-index: 100;
            margin: 24px auto;
            @include mobile {
                padding: 0px;
                top: 0px;
            }
        }
        &.feeds {
            .first-feed {
                width: 100%;
                height: 355px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .title {
                    font-size: $desktop30px;
                    line-height: 1.5;
                }
                .sub-title {
                    font-size: $desktop15px;
                }
            }
        }
    }
}