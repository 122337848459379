@import '../../styles/lib/mixin';
@import '../../styles/lib/animation';

.banner-container {
    width: 100%;
    height: 360px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #0003;
    overflow: hidden;
    @include blur-animation();

    @include mobile {
        height: 240px;
    }
    .banner-wrapper {
        @include wrapper(0px);
        position: relative;
        height: 100%;

        display: flex;
        align-items: center;

        .banner-text {
            color: #fff;
            font-size: $desktop28px;
            letter-spacing: -1.5px;
            word-break: keep-all;
            b {
                font-size: $desktop32px;
            }
        }
    }
}
