@import '../../styles/lib/mixin';
@import '../../styles/lib/skeleton';

html {
  body {
    .dialog {
      @include wrapper(40px);
      padding: 0;
      .app-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 500;
        background-color: transparent;
        box-shadow: none;
        .header {
          position: relative;
          @include wrapper(0);
          display: flex;
          align-items: center;
          flex-direction: row;
          background-color: #fff;
          height: 60px;
          box-shadow: $base-box-shadow;
          .title {
            font-size: $desktop17px;
            font-weight: 500;
            color: #333;
            @include text-overflow(1);
          }
          .close {
            position: absolute;
            top: 10px;
            right: 12px;
            width: 40px;
            height: 40px;
          }
        }
      }
      .wrapper {
        position: relative;
        margin-top: 60px;
        .comment-list {
          width: 100%;
          min-height: 100vh;
          padding: 0 12px;
        }
        .comment-box {
          position: sticky;
          bottom: 0;
          z-index: 900;
          display: flex;
          align-items: center;
          width: 100%;
          background: #fff;
          border: 1px solid #e5e5e5;
          padding: 12px 0;
        }
      }
    }
  }
}

.skeleton-title {
  @include skeleton-wrapper(40px, 5px);
  width: 90%;
  &::after {
    content: '';
    display: block;
    @include skeleton-box(100%, 100%);
    box-sizing: border-box;
  }
}
