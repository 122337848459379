@import '../../styles/lib/mixin';

.container {
  @include container();
  .content {
    @include wrapper(0px);
    position: relative;
    @include mobile {
      padding-top: 40px;
      padding-bottom: 80px;
    }
    .input {
      width: 100%;
      height: 48px;
      padding: 10px 15px;
      border: 1px solid;
      @include border-input();
    }
    .title-area {
      width: 100%;
      margin-bottom: 40px;
      @include mobile {
        margin-bottom: 20px;
      }
    }
    .content-area {
      .content {
        height: 450px;
        outline: none;
        resize: none;
        border: 1px solid;
        padding: 10px 15px;
        @include border-input();
        @include word-content();
      }
    }
  }
}
